import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const BackgroundImage = () => (
    <StaticImage
        src={'../../../images/backgrounds/mentr-header.jpg'}
        alt="Picture of hunting dog in a field"
        placeholder="blurred"
        layout="fullWidth"
    />
)

export default BackgroundImage
