import React from 'react'
import { Box } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import Header from '~components/casestudy/common/header'
import BackgroundImage from '~components/casestudy/mentr-cs/background-image'
import Product from '~components/casestudy/mentr-cs/product'
import Content from '~components/casestudy/common/content'

import SEOImage from '~images/socials/ls-pac-social.jpg'
import MentrContentImage from '~images/mentr/mentr-middle.jpg'

const LSimPACt = () => {
    return (
        <Box>
            <SEO
                title="LS-imPACt"
                description="LS-imPACt is a social media application targeted towards minority students in higher education that connects them with Mentors that can give them guidance throughout undergraduate, graduate school, and their career."
                image={SEOImage}
                article
            />
            <Header
                BgImage={BackgroundImage}
                name="LS-imPACt"
                descTitle="Building a New Future"
                desc="LS-imPACt is a revolutionary academics-focused social media platform.   
                LS-imPACt is a social media application targeted towards minority students 
                in higher education that connects them with mentors that can give them guidance 
                throughout undergraduate, graduate school, and their career.  While still in a
                pre-beta stage, LS-imPACt is available for download as a demo.  The full product
                is set to be launched sometime in August of 2021."
            />
            <Product />
            <Content
                image={MentrContentImage}
                tagline="Bringing a New Face to Academia"
                summary="LS-imPACt was started as an initiative to assist minority students in
                reaching post-graduate degrees by connecting with mentors that could give them
                the guidance they needed to succeed.  The app called for many social media elements,
                including connections, a new and fun way to search, an advanced matching algorithm, and
                a comprehensive chat feature for users.  LS-imPACt brought on Breach Software to
                build the app from the ground up.  They needed an aesthetic look and a fully
                furnished backend to handle their userbase of students and faculty at institutions
                from across the U.S."
                impact={[
                    {
                        header: 'Design',
                        body: `While LS-imPACt knew what they were looking for in terms of functionality
                        and usability, they granted Breach full control over UI decision-making.  Our team
                        began with creating a branding document based on studying other related industry
                        giants and LS-imPACt's own internal branding guidelines, and after refining it with 
                        their team, we created a frontend theme based on our document.  The flexibility
                        we had in design allowed for some truly creative features, including a tree-based
                        search feature, enabling users to narrow their results by selecting relevant branches.
                        The outcome of our design was a highly usable and accessible app that has the power
                        to change lives.`,
                    },
                    {
                        header: 'Development',
                        body: `Breach developed LS-imPACt using the React Native framework in order to save
                        development time on duplicating features between iOS and Android.  Our main focus was
                        on providing as much value as we could with each iteration of the app.  We worked
                        closely with the LS-imPACt team to clearly reflect the vision they had for the product,
                        and every aspect was written with quality and longevity in mind.  Our team built the
                        app to last and to be as easily maintainable as possible, and by using best practices
                        throughout all of our codebases, we are able to efficiently tackle new features that
                        arise at a moment's notice.`,
                    },
                    {
                        header: 'Deployment',
                        body: `Our team was responsible for the app from conception to deployment.  The LS-imPACt
                        team had peace of mind knowing that our team would handle all aspects of the product's
                        lifecycle.  With Breach, they were able to use a single team to deploy both iOS
                        and Android versions of the app without any extra effort on their part.  This
                        allowed them access to far more users than they otherwise would have.
                        LS-imPACt is available for both iOS and Android on their respective stores.`,
                    },
                ]}
                highlightColor="#b3cccc"
            />
        </Box>
    )
}

export default LSimPACt
