import React from 'react'
import { Flex, Box, Image, SimpleGrid } from '@chakra-ui/react'

import { FadeIn } from '../../shared/custom-animation'
import Image1 from '~images/mentr/mentr-image1.jpg'
import Image2 from '~images/mentr/mentr-image2.jpg'
import Image3 from '~images/mentr/mentr-image3.jpg'

const Product = () => {
    return (
        <Box
            backgroundColor="#b3cccc"
            style={{
                clipPath: 'polygon(0 7%, 100% 0, 100% 93%, 0 100%)',
            }}
            boxShadow="inset 35px 35px 70px #98adad,
            inset -35px -35px 70px #ceebeb"
            h={['auto']}
            w={'100%'}
        >
            <SimpleGrid
                columns={[1, 1, 3]}
                h={'100%'}
                pt={['5%', '5%', 0]}
                pb={['50%', '50%', '10%']}
            >
                <Flex justify="center" pt={'40%'}>
                    <FadeIn>
                        <Image
                            src={Image1}
                            objectFit="cover"
                            border="1px solid #ccc"
                            boxShadow="19px 19px 38px #98adad,
                            -19px -19px 38px #b7d1d1"
                            h={['600px', '600px', '400px', '600px']}
                        />
                    </FadeIn>
                </Flex>

                <Flex justify="center" pt={['10%', '10%', '30%']}>
                    <FadeIn>
                        <Image
                            src={Image2}
                            objectFit="cover"
                            border="1px solid #ccc"
                            boxShadow="19px 19px 38px #98adad,
                            -19px -19px 38px #b7d1d1"
                            h={['600px', '600px', '400px', '600px']}
                        />
                    </FadeIn>
                </Flex>

                <Flex justify="center" pt={['10%', '10%', '20%']}>
                    <FadeIn>
                        <Image
                            src={Image3}
                            objectFit="cover"
                            border="1px solid #ccc"
                            boxShadow="19px 19px 38px #98adad,
                            -19px -19px 38px #b7d1d1"
                            h={['600px', '600px', '400px', '600px']}
                        />
                    </FadeIn>
                </Flex>
            </SimpleGrid>
        </Box>
    )
}

export default Product
